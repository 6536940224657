// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {Zy7wAlc6C: {hover: true}};

const serializationHash = "framer-bRJLu"

const variantClassNames = {Zy7wAlc6C: "framer-v-dl9o8y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, deAUqHs2s: icon ?? props.deAUqHs2s ?? "TwitterLogo", w_ac_wV3R: link ?? props.w_ac_wV3R} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, deAUqHs2s, w_ac_wV3R, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Zy7wAlc6C", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={w_ac_wV3R}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-dl9o8y", className, classNames)} framer-bomta5`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Zy7wAlc6C"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(40, 40, 40))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-dda86418-62d7-4975-8ae2-0326e59e9f2a, rgb(24, 24, 24))", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} variants={{"Zy7wAlc6C-hover": {"--border-color": "rgb(51, 51, 51)", backgroundColor: "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(40, 40, 40))"}}} {...addPropertyOverrides({"Zy7wAlc6C-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-rjjcr1-container"} layoutDependency={layoutDependency} layoutId={"QPyFoqRXU-container"}><Phosphor color={"var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255))"} height={"100%"} iconSearch={"House"} iconSelection={deAUqHs2s} id={"QPyFoqRXU"} layoutId={"QPyFoqRXU"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bRJLu.framer-bomta5, .framer-bRJLu .framer-bomta5 { display: block; }", ".framer-bRJLu.framer-dl9o8y { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 6px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-bRJLu .framer-rjjcr1-container { flex: none; height: 14px; position: relative; width: 14px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bRJLu.framer-dl9o8y { gap: 0px; } .framer-bRJLu.framer-dl9o8y > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-bRJLu.framer-dl9o8y > :first-child { margin-left: 0px; } .framer-bRJLu.framer-dl9o8y > :last-child { margin-right: 0px; } }", ".framer-bRJLu[data-border=\"true\"]::after, .framer-bRJLu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OfRZBnXj0":{"layout":["auto","auto"]}}}
 * @framerVariables {"deAUqHs2s":"icon","w_ac_wV3R":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPjmzKd9sX: React.ComponentType<Props> = withCSS(Component, css, "framer-bRJLu") as typeof Component;
export default FramerPjmzKd9sX;

FramerPjmzKd9sX.displayName = "Component/Socmed Pill Button";

FramerPjmzKd9sX.defaultProps = {height: 26, width: 26};

addPropertyControls(FramerPjmzKd9sX, {deAUqHs2s: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "TwitterLogo", description: undefined, hidden: undefined, title: "Icon"}, w_ac_wV3R: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerPjmzKd9sX, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})